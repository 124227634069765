.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--even {
    @apply bg-light-grey;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--odd {
    @apply bg-white
}

.InovuaReactDataGrid__column-header__content {
    @apply text-violet text-[0.95rem]
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header {
    border-bottom: 2px solid #E0E0E0;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header--show-border-left,
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left {
    border: 0
}

.InovuaReactDataGrid__cell__content {
    @apply text-secondary
}

.InovuaReactDataGrid--theme-default-light,
.InovuaReactDataGrid__body {
    border-radius: 5px;
}

.no-border.InovuaReactDataGrid--theme-default-light {
    border: 0
}

.breakline .InovuaReactDataGrid__cell__content{
    white-space: break-spaces!important;
    overflow: hidden;
}

.inovua-react-toolkit-combo-box__input:focus {
    outline: none;
    background-color: transparent;
    border: 0px solid;
    box-shadow: none;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__cell:not(.InovuaReactDataGrid__cell--no-padding), .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__content, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__footer-row-cell, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header-group__title, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__locked-row-cell, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__nested-cell, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__nested-group-cell {
    padding: 7px;
}