.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
    @apply ring-0;
}

body {
    @apply text-secondary
}

h1, h2, h3, h4, h5 {
    @apply text-secondary;
}

p {
    font-size: 1.06rem;
}

.forensics-box h4 {
    @apply font-bold text-violet text-[0.95rem]
}

.forensics-box li {
    @apply mb-2
}

.performance-table {
    width: 100%;
}

.performance-table thead tr {
    border-bottom: 2px solid #E0E0E0;
}

.performance-table thead th,
.performance-table tbody tr th:first-child {
    font-size: 0.95rem;
    font-weight: 700;
    @apply text-violet;
}

.performance-table tbody tr:nth-child(even) {
    background-color: #f9f9fa;
}


.performance-table th {
    text-align: left;
    height: 40px;
    font-weight: normal;
    font-size: 14px;
    padding: 8px;
}

.accordion p, .accordion ul {
    @apply mb-4
}